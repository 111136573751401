<template>
  <div class="mock-result" :class="{skeleton : !state.loaded }">
    <template v-if="$store.state.account.loggedIn">
      <div class="envelope">
        <div class="img-wrapper">
          <span class="img" style="background-image: url(/assets/ico/page.order.paid.heart.svg)"></span>
        </div>
        <div class="name font-md">
          <router-link :to="`/reward/${$route.params.projectSeq}`" title="프로젝트 조회">
            <b>{{ state.project.projectName }}</b>
          </router-link>
        </div>
        <div class="txt font-base">
          <span>프로젝트에 투자해주셔서 감사합니다.</span>
        </div>
        <div class="ask">
          <div>
            <b>{{ $lib.getNumberFormat(state.investor.expenseAmt) }}{{ state.contestInfo.currencyUnit }}을(를) </b>
            <span>투자하였습니다.</span>
          </div>
        </div>
        <div class="guide">
          <span>투자 내역 확인은 </span>
          <span>
            <router-link to="/mypage/participant/mock" class="color-point">마이페이지</router-link>
            <span>에서 가능합니다.</span>
          </span>
        </div>
        <div class="sns">
          <div class="message">
            <span>프로젝트가 더 많은 분들에게 </span>
            <br class="d-block d-sm-none"/>
            <span>알려질 수 있도록 도와주세요.</span>
          </div>
          <div class="wrapper">
            <button class="btn img facebook" title="페이스북으로 공유" @click="share('facebook')" style="background-image: url(/assets/ico/common.sns.facebook.svg)"></button>
            <button class="btn img twitter" title="트위터로 공유" @click="share('twitter')" style="background-image: url(/assets/ico/common.sns.twitter.svg)"></button>
            <button class="btn img" title="카카오톡으로 공유" @click="share('kakaolink')" style="background-image: url(/assets/ico/common.sns.kakaotalk.svg)"></button>
            <button class="btn img" title="밴드로 공유" @click="share('band')" style="background-image: url(/assets/ico/common.sns.band.svg)"></button>
          </div>
        </div>
        <div class="to-list" v-if="state.contestSeq || !state.loaded">
          <router-link class="color-anchor font-sm" :to="`/partnership/${state.contestSeq}/mock`">
            <i class="fa fa-angle-left"></i>
            <span>프로젝트 목록으로 이동</span>
          </router-link>
        </div>
      </div>
      <div class="background" ref="backgroundRef" v-if="$env.device === 'desktop' && $env.browser !== 'IE' && state.visible">
        <Confetti :finish="removeBackground"/>
      </div>
      <div class="recommends" v-if="state.loaded">
        <div class="container">
          <DetailRelatives title="연관 프로젝트" :projectSeq="Number(state.project.projectSeq)" :projectCate="state.project.projectCate" simulationFlag="Y"/>
        </div>
      </div>
    </template>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import Number from "@/components/Number";
import Phone from "@/components/Phone";
import Address from "@/components/Address";
import NoLogin from "@/components/NoLogin";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Confetti from "@/components/Confetti";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import DetailRelatives from "@/pages/project/DetailRelatives";
import caches from "@/scripts/caches";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageMockOrderResult";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {DetailRelatives, Confetti, NoLogin, Address, Phone, Number},
  setup() {
    const component = new Component(async () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      const investorSeq = router.app.$route.params.investorSeq;

      // http 캐시 초기화
      caches.resetHttps();

      state.loaded = false;

      const res1 = await http.get(`/api/reward/projects/${projectSeq}`).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 403:
            store.commit("setSwingMessage", "이 페이지를 조회하실 수 있는 권한이 없습니다.");
            return router.replace({path: "/"});
        }
      }));

      state.loaded = true;

      if (res1?.error) {
        return;
      }

      state.loaded = false;

      const res2 = await http.get(`/api/reward/project/${projectSeq}/payments/result/${investorSeq}`).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 403:
            store.commit("setSwingMessage", "이 페이지를 조회하실 수 있는 권한이 없습니다.");
            return router.replace({path: "/"});
        }
      }));

      state.loaded = true;

      if (res2?.error) {
        return;
      }

      state.visible = true;
      state.contestSeq = res1.data.body.contestSeq;
      state.contestInfo = res1.data.body.contestInfo;
      state.project = res1.data.body.project;
      state.investor = res2.data.body;

      await store.dispatch("setDocumentTitle", state.project.projectName);
      await store.dispatch("initSnsShare");

      if (Array.isArray(res1.data.body.thumbNailList) && res1.data.body.thumbNailList.length) {
        state.project.thumbNailPath = res1.data.body.thumbNailList[0]?.thumbFilePath;
      }
    });

    const state = reactive({
      loaded: false,
      visible: false,
      contestSeq: "",
      contestInfo: {
        currencyUnit: "원"
      },
      project: {
        projectName: "Wait a moment",
        builderName: "Wait a moment",
        thumbNailPath: "",
      },
      investor: {
        paymethod: "",
        virtualCentercd: "",
        rvirno: "",
        expenseAmt: 0,
        scheduleAt: "",
      }
    });

    const backgroundRef = ref();
    const projectSeq = router.app.$route.params.projectSeq;

    const share = (platform) => {
      const url = location.origin + `/reward/${projectSeq}`;
      const title = state.project.projectName;
      const desc = state.project.simpleText;
      const imgUrl = store.getters.thumbnailUrl(state.project.thumbNailPath);
      const imgWidth = definitions.thumbnail.width;
      const imgHeight = definitions.thumbnail.height;
      store.dispatch("share", {platform, title, desc, url, imgUrl, imgWidth, imgHeight});
    };

    const removeBackground = () => {
      backgroundRef.value.remove();
    };

    return {component, state, backgroundRef, share, removeBackground};
  }
});
</script>

<style lang="scss" scoped>
.mock-result {
  padding: $px25 0 $px3 0;

  .envelope {
    text-align: center;
    margin: 0 auto;
    padding: $px65 0;
    background-image: url(/assets/img/page.order.paid.envelope.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 50%;
    height: 720px;
    max-width: 800px;

    > .img-wrapper {
      height: $px50;
      display: inline-block;
      overflow: hidden;

      .img {
        width: $px40;
        height: $px40;
      }
    }

    .name {
      a {
        position: relative;
        z-index: 1;
      }

      span, b {
        vertical-align: top;
      }

      b {
        display: inline-block;
        max-width: $px420;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 $px15;
      }
    }

    .txt {
      padding-top: $px3;
    }

    .ask {
      margin-top: $px15;
    }

    .guide {
      padding-top: $px8;
      position: relative;
      z-index: 1;
    }

    .sns {
      padding-top: $px15;

      > .wrapper {
        display: inline-block;
        padding-top: $px20;
        position: relative;
        z-index: 1;

        .btn {
          width: $px40;
          height: $px40;
          margin: $px5;
          box-shadow: none;
          border-radius: 50%;
          background-position: 50%;

          &.facebook {
            background-color: #445d98;
          }

          &.twitter {
            background-size: 120%;
            background-color: #2aade3;
          }
        }
      }
    }

    .to-list {
      padding-top: $px25;

      > a {
        position: relative;
        text-decoration: none;
        z-index: 1;

        i {
          margin-right: $px6;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
        }
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .recommends {
    border-top: $px1 solid #eee;
    background: #fff;
    padding: $px50 0 $px35 0;
    margin-top: $px-100;
    position: relative;
  }

  &.skeleton {
    .envelope {
      filter: grayscale(1);

      .img-wrapper {
        .img {
          @include skeleton;
        }
      }

      .name > a {
        @include skeleton;
      }

      .txt > span {
        @include skeleton;

        span {
          color: inherit !important;
        }
      }

      .ask > div {
        > b, > span {
          @include skeleton;
        }
      }

      .guide {
        a, span {
          @include skeleton;
        }
      }

      .sns {
        .message > span, button {
          @include skeleton;
        }
      }

      .to-list {
        > a {
          @include skeleton;

          > i {
            visibility: hidden;
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    .envelope {
      height: auto;
      background: none;
      padding: $px15;

      > .img-wrapper {
        height: $px85;

        .img {
          width: $px70;
          height: $px70;
        }
      }

      .name b {
        max-width: 100%;
      }
    }

    .recommends {
      margin-top: $px20;
      padding-top: $px30;
    }
  }
}
</style>